import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import colors from '../utils/colors';
import { ArrowForwardIos, FileDownload } from '@mui/icons-material';

const YouTubeDialog = ({ videoId, title, openByDefault = false }) => {
    const [open, setOpen] = useState(openByDefault);

    useEffect(() => {
        if (openByDefault) {
            setOpen(true);
        } else {
            setOpen(false)
        }
    }, [openByDefault]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle>
                {"  \n  " || ' '}

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 0,
                        padding: '10px',
                        marginBottom: '10px',
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{
                marginTop: '10px'
            }}>
                <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, backgroundColor: colors.blue }}>
                    {/* <iframe
                        title="YouTube video"
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                        src={`https://www.youtube.com/embed/${videoId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    /> */}
                    <iframe
                        title="Tutorial de pago"
                        loading="lazy"
                        style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, border: ' none', padding: 0, margin: 0 }}
                        src="https://www.canva.com/design/DAGUVMAuivs/XxIspKFO-tCwtCoAdiYkDg/watch?embed"
                        allowFullScreen="allowfullscreen"
                        allow="fullscreen"
                    />


                </div>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    endIcon={<FileDownload />}
                    sx={{
                        borderRadius: '15px',
                        bgcolor: '#007190',
                        textTransform: 'none',
                        height: '50px',
                        color: '#EFF9FF',
                        mt: 2,
                        '&:hover': {
                            bgcolor: '#007070'
                        },
                    }}
                    onClick={() => {
                        const newWindow = window.open('https://firebasestorage.googleapis.com/v0/b/apoderados-fd064.appspot.com/o/Como%20Comprar%20tu%20bingo.pdf?alt=media&token=946e1a4f-5509-4267-9d3d-5a274e542f6d', '_blank', 'noopener,noreferrer');
                        if (newWindow) newWindow.opener = null;
                    }}
                >
                    Descargar ayuda en PDF
                </Button>

            </DialogContent>
        </Dialog>
    );
};

export default YouTubeDialog;
