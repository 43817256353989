import React, { useEffect, useState } from 'react'
import ReusableDrawer from '../components/drawer'
import { Box, Container, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { AttachMoney, Download, UploadFile } from '@mui/icons-material';
import colors from '../utils/colors';
import Swal from 'sweetalert2';
import { getValuePay, updateValuePay } from '../database/pays';
import loadingImg from '../assets/loading.svg';


export default function Settings() {

    const [valuePay, setValuePay] = useState(0);

    const formatter = new Intl.NumberFormat('es-CL', {
        style: 'currency',
        currency: 'CLP',
        minimumFractionDigits: 0
    });

    const [drawerOpen, setDrawerOpen] = useState(true);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    useEffect(() => {
        async function getData() {
            Swal.fire({
                imageUrl: loadingImg,
                imageWidth: 300,
                imageHeight: 300,
                title: "Cargando configuraciones...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
            const response = await getValuePay();
            console.log(response);
            
            setValuePay(response);
            Swal.close();
        }
        getData();
    }, []);

    async function changeValuePay() {
        const { value: newValue } = await Swal.fire({
            title: `Cambiar valor de equipo \n(Actual ${formatter.format(valuePay)})`,
            html: `
            <input type="text" id="input-value" class="swal2-input" placeholder="Ingresa el nuevo valor">
          `,
            showCancelButton: true,
            confirmButtonText: 'Cambiar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: false,
            confirmButtonColor: colors.green,
            cancelButtonColor: colors.red,
            preConfirm: () => {
                const input = document.getElementById('input-value');
                const rawValue = input.value.replace(/[^\d]/g, ''); // Eliminar cualquier carácter no numérico
                if (!rawValue) {
                    Swal.showValidationMessage('Debes ingresar un valor');
                }
                return rawValue;
            },
            didOpen: () => {
                const input = document.getElementById('input-value');
                input.setAttribute('maxlength', '10');
                input.addEventListener('input', () => {
                    const rawValue = input.value.replace(/[^\d]/g, ''); // Eliminar cualquier carácter no numérico
                    if (rawValue) {
                        const formatter = new Intl.NumberFormat('es-CL', {
                            style: 'currency',
                            currency: 'CLP',
                            minimumFractionDigits: 0
                        });
                        input.value = formatter.format(rawValue);
                    } else {
                        input.value = '';
                    }
                });

                input.addEventListener('focus', () => {
                    input.value = input.value.replace(/[^\d]/g, '');
                });

                input.addEventListener('blur', () => {
                    const rawValue = input.value.replace(/[^\d]/g, '');
                    if (rawValue) {
                        const formatter = new Intl.NumberFormat('es-CL', {
                            style: 'currency',
                            currency: 'CLP',
                            minimumFractionDigits: 0
                        });
                        input.value = formatter.format(rawValue);
                    }
                });
            }
        });

        if (newValue) {

            const formattedValue = formatter.format(newValue);
            newValue.replace(/[^\d]/g, ''); // Eliminar cualquier carácter no numérico

            const response = await updateValuePay(parseInt(newValue));
            if (response) {
                setValuePay(newValue);
                Swal.fire({
                    title: `El valor de equipo ha cambiado a: ${formattedValue}`,
                    text: '',
                    confirmButtonText: 'Aceptar',
                    confirmButtonColor: colors.green,
                    icon: 'success'
                });
            }
        } else {
            Swal.fire('Cambio cancelado', '', 'info');
        }
    }

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100vh',
            width: '100vw',
        }}>
            <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'settings'} />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                alignItems: 'center',
            }}>
                <h2 style={{
                    color: colors.blue,
                }}>Ajustes</h2>
                <Container sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <ListItem
                        onClick={() => {
                            changeValuePay();
                        }}
                        sx={{
                            backgroundColor: colors.white,
                            border: `1px solid ${colors.border}`,
                            borderRadius: '10px'
                        }} button>
                        <ListItemText primaryTypographyProps={{
                            fontFamily: 'Nunito'
                        }} primary="Cambiar valor de cuota" />
                        <ListItemIcon>
                            <AttachMoney sx={{}} />
                        </ListItemIcon>
                    </ListItem>


                    {/* <ListItem
                        onClick={() => {

                        }}
                        sx={{
                            backgroundColor: colors.white,
                            border: `1px solid ${colors.border}`,
                            borderRadius: '10px',
                            mt: 2
                        }} button>
                        <ListItemText primaryTypographyProps={{
                            fontFamily: 'Nunito'
                        }} primary="Subir planilla de estudiantes" />
                        <ListItemIcon>
                            <UploadFile sx={{}} />
                        </ListItemIcon>
                    </ListItem> */}

                </Container>
            </Box>
        </div>
    )
}
