import { collection, query, where, onSnapshot, doc, getDoc, orderBy, getDocs, addDoc, serverTimestamp, updateDoc, writeBatch, setDoc, limit, deleteDoc } from 'firebase/firestore';
import { db } from './config_firebase';
import formatRUT from '../utils/format_rut';
import { capitalizeWords } from '../utils/capitalize';

export const createFamilyMulti = async (family) => {
    const batch = writeBatch(db);
    const childRefs = [];

    try {
        const familyRef = doc(collection(db, 'families'));
        await setDoc(familyRef, {
            dad: family.dad,
            payed: false,
            mom: family.mom,
            email: family.email,
            responsible: family.responsible,
            address: family.address,
            createdAt: serverTimestamp(),
        });

        for (const child of family.children) {
            const childRef = doc(collection(db, 'students'));
            childRefs.push(childRef.id);
            batch.set(childRef, {
                name: child.name,
                lastNameP: child.lastNameP,
                lastNameM: child.lastNameM,
                course: child.course,
                rut: child.rut,
                familyId: familyRef.id,
            });
        }

        await batch.commit();

        await updateDoc(familyRef, {
            children: childRefs,
        });

        return true;
    } catch (error) {
        console.error('Error creating family:', error);
        return false;
    }
};

export const createFamily = async (family) => {
    console.log(family);

    const childRefs = [];

    try {
        const familyRef = doc(collection(db, 'families'));
        await setDoc(familyRef, {
            dad: family.dad,
            payed: false,
            mom: family.mom,
            email: family.email,
            responsible: family.responsible,
            address: family.address,
            createdAt: serverTimestamp(),
        });

        for (const child of family.children) {
            const childRef = doc(collection(db, 'students'));
            childRefs.push({
                id: childRef.id,
                course: child.course
            });
            await setDoc(childRef, {
                name: child.name,
                lastNameP: child.lastNameP,
                lastNameM: child.lastNameM,
                course: child.course,
                enrollmentNumber: child.enrollmentNumber,
                rut: child.rut,
                familyId: familyRef.id,
            });
        }

        await updateDoc(familyRef, {
            children: childRefs,
        });

        return true;
    } catch (error) {
        console.error('Error creating family:', error);
        return false;
    }
};

export const updateFamily = async (familyId, updatedFamily) => {
    console.log(familyId);
    console.log(updatedFamily);


    try {
        const familyRef = doc(db, 'families', familyId);

        console.log(familyRef.id);


        const existingChildIds = updatedFamily.existingChildIds || [];

        console.log(existingChildIds);


        await updateDoc(familyRef, {
            dad: updatedFamily.dad,
            mom: updatedFamily.mom,
            email: updatedFamily.email,
            responsible: updatedFamily.responsible,
            address: updatedFamily.address,
            payed: updatedFamily.payed,
        });

        console.log("Termina de actualizar");


        const newChildIds = [];

        for (const child of updatedFamily.children) {
            console.log(child);

            if (child.id) {
                const childRef = doc(db, 'students', child.id);
                await updateDoc(childRef, {
                    name: child.name,
                    lastNameP: child.lastNameP,
                    lastNameM: child.lastNameM,
                    course: child.course,
                    rut: child.rut,
                });
                newChildIds.push(child.id);
                console.log("Si entra al if");

            } else {
                const newChildRef = doc(collection(db, 'students'));
                await setDoc(newChildRef, {
                    name: child.name,
                    lastNameP: child.lastNameP,
                    lastNameM: child.lastNameM,
                    course: child.course,
                    rut: child.rut,
                    familyId: familyId,
                });
                newChildIds.push(newChildRef.id);
            }
        }

        const childrenToRemove = existingChildIds.filter(id => !newChildIds.includes(id));
        for (const childId of childrenToRemove) {
            const childRef = doc(db, 'students', childId);
            await deleteDoc(childRef);
        }

        await updateDoc(familyRef, {
            children: newChildIds,
        });

        return true;
    } catch (error) {
        console.error('Error updating family:', error);
        return false;
    }
};

const sortCourses = (courses) => {
    // Mapeo de niveles para el sistema educativo de Chile
    const levelOrder = {
        "Pre-Kinder": 1,
        "Kinder": 2,
        "Primero": 3,
        "Segundo": 4,
        "Tercero": 5,
        "Cuarto": 6,
        "Quinto": 7,
        "Sexto": 8,
        "Séptimo": 9,
        "Octavo": 10,
        "Primero-Medio": 11,
        "Segundo-Medio": 12,
        "Tercero-Medio": 13,
        "Cuarto-Medio": 14,
    };

    return courses.sort((a, b) => {
        // Extraer el nivel y subnivel del curso
        const [nameA, sublevelA] = a.split(/-(.+)/); // Divide en el primer guion
        const [nameB, sublevelB] = b.split(/-(.+)/);

        // Obtener el orden basado en el mapeo
        const levelA = levelOrder[nameA.trim()] || 999; // Por defecto 999 para no definidos
        const levelB = levelOrder[nameB.trim()] || 999;

        // Ordenar primero por nivel educativo
        if (levelA !== levelB) {
            return levelA - levelB;
        }

        // Si están en el mismo nivel, ordenar por subnivel (A, B, etc.)
        return (sublevelA || "").localeCompare(sublevelB || "");
    });
};


export const getFamilies = async (type, course) => {
    console.log("Buscando", type, course);

    try {
        const familiesCollection = collection(db, 'pays_students');
        let q;

        q = query(familiesCollection, orderBy('payedAt', 'desc'));
        const querySnapshot = await getDocs(q);

        let coursesSet = new Set();

        const families = querySnapshot.docs
            .map(doc => {
                const familyData = doc.data();

                familyData.students.forEach(student => {
                    coursesSet.add(student.course);
                });

                if (course === "all") {
                    return {
                        id: doc.id,
                        alias: familyData.nameApoderado,
                        ...familyData,
                    };
                } else {
                    const matchingChildren = familyData.students.filter(child => child.course === course);
                    if (matchingChildren.length > 0) {
                        return {
                            id: doc.id,
                            alias: familyData.nameApoderado,
                            ...familyData,
                        };
                    }
                }
                return null;
            })
            .filter(family => family !== null);
        const uniqueCourses = Array.from(coursesSet);
        return { families, uniqueCourses: sortCourses(uniqueCourses) };
    } catch (error) {
        console.error('Error fetching families:', error);
        return { families: [], uniqueCourses: [] };
    }
};

export const getNamesPayed = async (type, course) => {
    try {
        const familiesCollection = collection(db, 'pays_students');
        const q = query(familiesCollection, orderBy('payedAt', 'desc'));
        const querySnapshot = await getDocs(q);

        const studentNamesSet = new Set();

        querySnapshot.docs.forEach(doc => {
            const familyData = doc.data();

            familyData.students.forEach(student => {
                if (course === "all" || student.course === course) {
                    studentNamesSet.add(student.nameStudent);
                }
            });
        });

        const studentNames = Array.from(studentNamesSet);
        return { studentNames };
    } catch (error) {
        console.error('Error fetching student names:', error);
        return { studentNames: [] };
    }
};


export const getFamilyByRutRepresent = async (rutRepresent) => {
    console.log("Se busca", formatRUT(rutRepresent));

    try {
        const familiesCollection = collection(db, 'families');

        // Consultar por el rut del padre o de la madre
        const q1 = query(familiesCollection, where('dad.rut', '==', formatRUT(rutRepresent)));
        const q2 = query(familiesCollection, where('mom.rut', '==', formatRUT(rutRepresent)));

        const querySnapshotDad = await getDocs(q1);
        const querySnapshotMom = await getDocs(q2);

        console.log(querySnapshotDad.docs.length, 'del papi');

        let family = querySnapshotDad.docs.map(doc => ({
            id: doc.id,
            type: 'dad',
            ...doc.data(),
        }));


        if (family.length === 0) {
            console.log(querySnapshotMom.docs.length, 'de la mami');
            family = querySnapshotMom.docs.map(doc => ({
                id: doc.id,
                type: 'mom',
                ...doc.data(),
            }));
        }

        // Si no encontramos en los padres, consultar por los hijos
        if (family.length === 0) {
            const studentsCollection = collection(db, 'students');
            const q3 = query(studentsCollection, where('rut', '==', formatRUT(rutRepresent)));
            const querySnapshotChild = await getDocs(q3);

            console.log(querySnapshotChild.docs.length, ' aquiii');


            const children = querySnapshotChild.docs.map(doc => ({
                familyId: doc.data().familyId,
            }));

            if (children.length > 0) {
                const familyId = children[0].familyId;
                const familyDoc = await getDoc(doc(db, 'families', familyId));
                family = [{ id: familyDoc.id, ...familyDoc.data(), type: 'student', dataStudent: querySnapshotChild.docs[0].data() }];
            }
        }

        console.log(family);


        if (family.length > 0) {
            if (family[0].payed === true) {
                return family[0];
            } else {
                return 'not payed';
            }
        } else {
            return false;
        }

    } catch (error) {
        console.error('Error fetching family by rut:', error);
        return null;
    }
};


export const getStudentsFromUnpaidFamilies = async () => {
    try {
        // Obtener las familias con estado de pago falso
        const familiesCollection = collection(db, 'families');
        const familiesQuery = query(familiesCollection, where('payed', '==', false));
        const familiesSnapshot = await getDocs(familiesQuery);

        const unpaidFamilyIds = familiesSnapshot.docs.map(doc => doc.id);
        const familiesData = familiesSnapshot.docs.map(doc => {
            return {
                name: doc.data().alias,
                id: doc.id,
                students: doc.data().children
            }
        });


        if (unpaidFamilyIds.length === 0) {
            return [];
        }

        // // Obtener los estudiantes que pertenecen a esas familias
        // const studentsCollection = collection(db, 'students');
        // const studentsQuery = query(studentsCollection, where('familyId', 'in', unpaidFamilyIds));
        // const studentsSnapshot = await getDocs(studentsQuery);

        // const students = studentsSnapshot.docs.map(doc => ({
        //     id: doc.id,
        //     ...doc.data(),
        // }));

        // const response = familiesSnapshot.docs.map(async doc => {
        //     return {
        //         name: doc.data().alias,
        //         id: doc.id,
        //         students: doc.data().children
        //     }
        // });

        return familiesData;
    } catch (error) {
        console.error('Error fetching students from unpaid families:', error);
        return [];
    }
};

export const getChildren = async (idFamily) => {
    console.log("Buscando hijos para el id de familia:", idFamily);
    try {
        const studentsCollection = collection(db, 'students');
        const q = query(
            studentsCollection,
            where('familyId', '==', idFamily)
        );
        const querySnapshot = await getDocs(q);

        console.log("Documentos encontrados:", querySnapshot.docs);

        const children = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        return children;
    } catch (error) {
        console.error('Error al obtener los hijos:', error);
        return [];
    }
};
