import * as XLSX from 'xlsx';
import { capitalizeWords } from './capitalize';

export const generateExcelFamilies = (data) => {
    const processedData = data.map((item) => {
        const studentNames = item.students
            .map((student) => student.nameStudent)
            .join(', ');
        const formattedDate = item.payedAt.toDate(); 
        const dateString = `${formattedDate.getDate().toString().padStart(2, '0')}-${(formattedDate.getMonth() + 1).toString().padStart(2, '0')}-${formattedDate.getFullYear()}`;

        return {
            'Rut Apoderado': item.rutApoderado,
            'Nombre Apoderado': item.nameApoderado,
            'Correo Electrónico': item.emailApoderado,
            'Fecha de Pago': dateString,
            'Estudiantes': studentNames,
        };
    });

    const worksheet = XLSX.utils.json_to_sheet(processedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Pagos');
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    const fileName = `Detalle_Familias - Exportado-${formattedDate}.xlsx`;
    XLSX.writeFile(workbook, fileName);
};

export const generateExcelStudents = (students) => {
    const courseOrder = [
        'Pre-Kinder', 'Kinder', 'Primero', 'Segundo', 'Tercero', 'Cuarto', 'Quinto', 'Sexto', 'Séptimo', 'Octavo',
        'Primero-Medio', 'Segundo-Medio', 'Tercero-Medio', 'Cuarto-Medio'
    ];


    const dataGroupedByCourse = {};

    students.forEach(estudiante => {
        if (estudiante && estudiante.curso && estudiante.nombre) {
            if (!dataGroupedByCourse[estudiante.curso]) {
                dataGroupedByCourse[estudiante.curso] = [];
            }
            dataGroupedByCourse[estudiante.curso].push(estudiante);
        }
    });

    const workbook = XLSX.utils.book_new(); 

    const sortCourses = (courseA, courseB) => {
        const courseOrderIndexA = courseOrder.indexOf(courseA.split('-')[0]);
        const courseOrderIndexB = courseOrder.indexOf(courseB.split('-')[0]);

        if (courseOrderIndexA === courseOrderIndexB) {
            return courseA.localeCompare(courseB);
        }

        return courseOrderIndexA - courseOrderIndexB;
    };

    const sortedCourses = Object.keys(dataGroupedByCourse).sort(sortCourses);

    sortedCourses.forEach(curso => {
        if (dataGroupedByCourse[curso]) {
            const estudiantes = dataGroupedByCourse[curso];

            estudiantes.sort((a, b) => a.nombre.localeCompare(b.nombre));

            const dataExcelCurso = [
                ['Curso', 'Nombre', '¿Pagado?'] // Cabecera
            ];

            estudiantes.forEach(estudiante => {
                dataExcelCurso.push([
                    estudiante.curso,
                    estudiante.nombre,
                    estudiante.isPaid ? 'Sí' : 'No'
                ]);
            });

            const worksheet = XLSX.utils.aoa_to_sheet(dataExcelCurso);

            XLSX.utils.book_append_sheet(workbook, worksheet, curso);
        }
    });

    XLSX.writeFile(workbook, 'Reporte_Estudiantes_por_Curso.xlsx');
};
