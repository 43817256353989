import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Typography, Box, Paper, Icon, useMediaQuery, Divider, FormControl, Select, MenuItem, InputAdornment, IconButton } from '@mui/material';
import { Search as SearchIcon, Payment as PaymentIcon, CheckOutlined, CheckCircleOutlineOutlined, Delete, CheckCircle } from '@mui/icons-material';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../database/config_firebase';
import salle from '../assets/logo-bingo.svg';
import colors from '../utils/colors';
import formatToClp from '../utils/format_to_clp';
import Swal from 'sweetalert2';
import loadingImg from '../assets/loading.svg';
import { generateRandomString } from '../utils/generate_string';
import getSignature from '../utils/get_signature';
import { useLocation } from 'react-router-dom';
import { checkPayApoderadoByRut, getValuePay, savePay, savePayment } from '../database/pays';
import axios from 'axios';
import { capitalizeWords } from '../utils/capitalize';
import { useTheme } from '@emotion/react';
import { convertirCurso, getBingoStudents, verifyStudent } from '../database/students';
import ModalVideoApoderados from '../components/modal_video_apoderados';


const PayPage = () => {
    const [rut, setRut] = useState('');
    const [email, setEmail] = useState('');
    const [emailConfirm, setEmailConfirm] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const [idFamily, setIdFamily] = useState("");
    const [idPay, setIdPay] = useState("")
    const location = useLocation();
    const [students, setStudents] = useState([]);
    const [bingoStudents, setBingoStudents] = useState([])
    const [studentsInCourse, setStudentsInCourse] = useState([])
    const [openModal1, setOpenModal1] = useState(true)
    const [rutStudent, setRutStudent] = useState('')


    const [screenNumber, setScreenNumber] = useState(1)

    const handleNextScreen = () => {
        setScreenNumber((prev) => Math.min(prev + 1, 3));
    };

    const handlePreviousScreen = () => {
        setScreenNumber((prev) => Math.max(prev - 1, 1));
    };


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleStudentChange = (index, field, value) => {
        const updatedStudents = [...students];
        updatedStudents[index][field] = value;
        setStudents(updatedStudents);
    };


    const handleStudentSelectChange = async (index, selectedStudentId) => {
        // Swal.fire({
        //     customClass: {
        //         popup: 'swal-overlay'
        //     },
        //     imageUrl: loadingImg,
        //     imageWidth: 300,
        //     imageHeight: 300,
        //     title: "Cargando...",
        //     showConfirmButton: false,
        //     allowOutsideClick: false,
        //     allowEscapeKey: false,
        // });
        const student2 = studentsInCourse.find(student => student.id === selectedStudentId);

        // const exists = true;//TODO aqui
        // if (exists) {
        //     handleStudentChange(index, 'selectedStudent', selectedStudentId);
        //     handleStudentChange(index, 'nameStudent', `${capitalizeWords(student2.nombre)} ${capitalizeWords(student2.apellido)}`);
        //     Swal.fire({
        //         icon: 'success',
        //         text: "El bingo obligatorio del estudiante ya se encuentra pagado, si deseas comprar uno extra puedes ingresar el N° de la tarjeta",
        //         title: 'Ya pagado'
        //     })
        // } else {
        handleStudentChange(index, 'selectedStudent', selectedStudentId);
        handleStudentChange(index, 'nameStudent', `${capitalizeWords(student2.nombre)} ${capitalizeWords(student2.apellido)}`);
        // Swal.close();

        // }
    };

    const validateFields = async () => {
        let errors = []
        let existsRut = await checkPayApoderadoByRut(rut);
        if (existsRut === true) {
            Swal.fire({
                icon: 'warning',
                title: 'Pagado',
                text: 'El rut indicado ya pagó como apoderado.'
            })
            return;
        } else if (existsRut === 'error') {
            Swal.fire({
                icon: 'error',
                title: 'Error al validar',
                text: 'Ha ocurrido un error mientras se validaba tu rut, por favor inténtalo nuevamente.'
            })
            return;
        }

        if (!rut || !name || !email || !emailConfirm) {
            errors.push('dato')
        }

        if (email !== emailConfirm) {
            errors.push('email')
        }

        for (const student of students) {
            if (!student.course || !student.name) {
                errors.push(student)
            }
        }

        if (errors.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Completar datos',
                text: 'Debes completar todos los datos y los correos ingresados deben ser iguales. Revisa e inténtalo nuevamente.'
            })
        } else if (students.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Completar datos',
                text: 'Debes agregar al menos un estudiante. Inténtalo nuevamente.'
            })
        } else {
            setScreenNumber(2)
        }
    };

    const handleRemoveStudent = (index) => {
        const updatedStudents = students.filter((_, i) => i !== index);
        setStudentsInCourse([])
        setStudents(updatedStudents);
    };

    const handleAddStudent = (index) => {
        const updatedStudents = [...students];
        const currentStudent = updatedStudents[index];

        if (students.length === 0) {
            setStudents([...students,
            { nameStudent: '', course: '', isComplete: false }
            ])
        } else {
            if (currentStudent.course && currentStudent.selectedStudent) {
                currentStudent.isComplete = true;
                setStudents(updatedStudents);
                setStudents([...students,
                { nameStudent: '', course: '', isComplete: false }
                ])
                setStudentsInCourse([])
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Completar datos',
                    text: "Para agregar un nuevo alumno debes completar toda la información del alumno actual."
                })
            }
        }
    };

    const formatRut = (rut) => {
        let cleanRut = rut.replace(/[\.\-]/g, '').trim().toUpperCase();
        if (cleanRut.length < 8) return cleanRut; // Devuelve sin formatear si no tiene longitud suficiente

        const dv = cleanRut.slice(-1);
        const num = cleanRut.slice(0, -1);
        const formattedNum = num.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        return `${formattedNum}-${dv}`;
    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;

        switch (name) {
            case 'email':
                setEmail(value);
                break;
            case 'rut':
                setRut(formatRut(value));
                break;
            case 'emailConfirm':
                setEmailConfirm(value);
                break;
            case 'name':
                setName(value);
                break;
            case 'rutStudent':
                setRutStudent(value);
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        async function getData() {
            console.log("Students", students);

            setStudents([]);
            Swal.fire({
                customClass: {
                    popup: 'swal-overlay'
                },
                imageUrl: loadingImg,
                imageWidth: 300,
                imageHeight: 300,
                title: "Cargando...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });

            const response = await getBingoStudents();
            setBingoStudents(response)
            Swal.close();
        }

        getData();
    }, [])


    useEffect(() => {

        const url = window.location.href;
        const parsedUrl = new URL(url);
        const params = new URLSearchParams(parsedUrl.search);

        async function handlePay() {
            Swal.fire({
                imageUrl: loadingImg,
                imageWidth: 300,
                imageHeight: 300,
                title: "Cargando...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });


            let res = params.get('res');
            let nameParams = params.get('name');
            let emailParams = params.get('email');
            let amountParams = params.get('x_amount');
            let referenceParams = params.get('x_reference');
            let timestampParams = params.get('x_timestamp');
            let signatureParams = params.get('x_signature');

            const studentsParam = params.get('students');
            let studentsParams;
            if (studentsParam) {
                studentsParams = JSON.parse(decodeURIComponent(studentsParam));
            }



            const rutFromUrl = params.get('rut');
            if (rutFromUrl) {
                formatRut(rutFromUrl);
            }

            if ((location.pathname.includes('res=canceled') || location.search.includes('res=canceled'))) {
                setOpenModal1(false)
                Swal.fire({
                    title: "Pago cancelado",
                    text: "Has cancelado el pago. Puedes intentarlo nuevamente.",
                    icon: 'warning',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.history.pushState({}, '', '/pay');
                    }
                });
            } else if (res === 'completed') {
                setOpenModal1(false)
                setStudents(studentsParams)
                setName(nameParams)
                setEmail(emailParams)
                setRut(rutFromUrl)
                setScreenNumber(3)
                Swal.fire({
                    imageUrl: loadingImg,
                    imageWidth: 300,
                    imageHeight: 300,
                    title: "Cargando...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });



                const res1 = await savePayment(
                    studentsParams,
                    rutFromUrl,
                    nameParams,
                    emailParams,
                    referenceParams,
                    signatureParams,
                    timestampParams
                );

                const modifiedStudentsParams = studentsParams.map(student => ({
                    ...student,
                    nameStudent: student.name,
                    name: undefined
                }));


                if (res1 === true) {
                    await axios.post('https://us-central1-apoderados-fd064.cloudfunctions.net/sendVoucherPayCenter', {
                        name: nameParams,
                        rut: rutFromUrl,
                        email: emailParams,
                        students: modifiedStudentsParams
                    })
                    Swal.close();
                } else if (res1 === 'exists') {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Pagado',
                        text: "Ya se encuentra pagada la cuota asignada a este rut de apoderado."
                    })
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'No se ha podido completar tu pago. ',
                        text: "Ha ocurrido un error mientras se intentaba realizar tu pago. Contacta con soporte para ayudarte."
                    })
                }

                console.log(studentsParam, "Coma",
                    rutFromUrl,
                    nameParams,
                    emailParams,
                    referenceParams,
                    signatureParams,
                    timestampParams);
            }
        }

        handlePay();

    }, [location]);


    const handlePayment = () => {
        console.log(email);
        console.log(process.env.REACT_APP_FIREBASE_SECRET_KEY);
        let referenceID = `${generateRandomString(6)}`;
        console.log(referenceID);

        Swal.fire({
            imageUrl: loadingImg,
            imageWidth: 300,
            imageHeight: 300,
            title: "Creando el pago...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        const secretKey = process.env.REACT_APP_FIREBASE_SECRET_KEY;
        let document = {
            x_account_id: "95493506",
            x_amount: '33000',
            x_currency: "CLP",
            x_customer_email: email,
            x_customer_first_name: "",
            x_customer_last_name: "",
            x_customer_phone: "",
            x_description: "Pago de colegio",
            x_reference: referenceID,
            x_shop_country: "CL",
            x_shop_name: "La Salle Temuco",
            x_url_callback: "https://www.lasalle.cl",
            x_url_cancel: "https://salle-temuco.maxipos.cl/pay?res=canceled&",
            x_url_complete: `https://salle-temuco.maxipos.cl/pay?res=completed&rut=${encodeURIComponent(rut)}&name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&students=${encodeURIComponent(JSON.stringify(students))}&`
        };

        let signature = getSignature(document, secretKey);
        document.x_signature = signature;
        try {
            fetch('https://us-central1-apoderados-fd064.cloudfunctions.net/payment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(document),
            })
                .then(response => response.json())
                .then(data => {
                    console.log("respuesta", data);
                    if (data.url) {
                        console.log(data);
                        const url = data.url;
                        const parts = url.split("/");
                        setIdPay(parts[parts.length - 1]);
                        window.location.replace(data.url);
                    }
                })
                .catch(error => console.error('Error:', error));
        } catch (error) {
            console.error('Error:', error);
        }


        console.log(signature);
    };

    const formatRutWithoutDots = (rut) => {
        let cleanRut = rut.replaceAll('.', '').replaceAll('-', '')
        const body = cleanRut.slice(0, -1);
        const verifier = cleanRut.slice(-1);
        return `${body}-${verifier}`;
    };

    const handleVerify = async () => {
        console.log("Inicia");
        let rutFormatted = formatRutWithoutDots(rutStudent)
        console.log("busca", rutFormatted);
        let res = await verifyStudent(rutFormatted);
        console.log(res);
        setStudents(res)
    }

    return (

        <div style={{
            backgroundColor: colors.blue,
            width: '100vw',
            height: '100vh',
            // position: 'fixed',
            top: 0,
            left: 0,
            overflowX: 'hidden',
            // '&::-webkit-scrollbar': { display: 'none' },
            zIndex: 0
        }}>
            <Container maxWidth="sm" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                    src={salle}
                    alt="Logo"
                    style={{ marginBottom: '20px', marginTop: '10%', borderRadius: '10px', maxWidth: '400px' }}
                />
                {/* <Typography variant={isMobile ? "h6" : "h5"} gutterBottom sx={{
                    color: colors.yellow,
                    textAlign: 'center'
                }}>
                    Paga como apoderado
                </Typography> */}

                {screenNumber == 1 && (
                    <Paper elevation={0} style={{
                        padding: '20px',
                        width: '100%',
                        backgroundColor: '#DFF2FF',
                        textAlign: 'center',
                        borderRadius: '15px'
                    }}>
                        <Typography sx={{
                            fontFamily: 'Nunito',
                            marginBottom: '10px',
                            fontWeight: 'bold',
                            color: colors.blue
                        }} variant="h4" gutterBottom>
                            Paga aquí tu aporte al Centro de Padres
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Nunito',
                            fontSize: '14px',
                            marginBottom: '20px'
                        }} gutterBottom>
                            Aquí puedes pagar tu cuota del centro de padres, ingresa tus datos, agrega tus hijos y continúa con el pago.
                        </Typography>
                        <Box display="grid" gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"} gap={2} mb={1}>
                            <Box>
                                <Typography textAlign={'left'} color={'#06314E'} sx={{ fontSize: '15px' }}>Rut apoderado</Typography>
                                <TextField
                                    variant="outlined"
                                    name="rut"
                                    value={rut}
                                    onChange={handleInputChange}
                                    placeholder='Rut apoderado'
                                    sx={{
                                        width: '100%',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                            backgroundColor: '#06314E17',
                                            color: '#0090B5',
                                            '& fieldset': {
                                                borderColor: 'transparent',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'transparent',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'transparent',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#00796B',
                                        },
                                    }}
                                />
                            </Box>
                            <Box>
                                <Typography textAlign={'left'} color={'#06314E'} sx={{ fontSize: '15px' }}>Nombre completo</Typography>
                                <TextField
                                    variant="outlined"
                                    name="name"
                                    value={name}
                                    onChange={handleInputChange}
                                    placeholder='Nombre'
                                    sx={{
                                        width: '100%',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                            backgroundColor: '#06314E17',
                                            color: '#0090B5',
                                            '& fieldset': {
                                                borderColor: 'transparent',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'transparent',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'transparent',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#00796B',
                                        },
                                    }}
                                />
                            </Box>

                        </Box>

                        <Box display="grid" gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"} gap={2} mb={1}>
                            <Box>
                                <Typography textAlign={'left'} color={'#06314E'} sx={{ fontSize: '15px' }}>Email</Typography>
                                <TextField
                                    placeholder="Email"
                                    variant="outlined"
                                    name="email"
                                    type='email'
                                    value={email}
                                    onChange={handleInputChange}
                                    sx={{
                                        width: '100%',

                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                            backgroundColor: '#06314E17',
                                            color: '#0090B5',
                                            '& fieldset': {
                                                borderColor: 'transparent',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'transparent',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'transparent',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#00796B',
                                        },
                                    }}
                                />
                            </Box>
                            <Box>
                                <Typography textAlign={'left'} color={'#06314E'} sx={{ fontSize: '15px' }}>Repetir email</Typography>
                                <TextField
                                    placeholder="Repetir Email"
                                    variant="outlined"
                                    name="emailConfirm"
                                    type='email'
                                    value={emailConfirm}
                                    onChange={handleInputChange}
                                    sx={{
                                        width: '100%',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '12px',
                                            backgroundColor: '#06314E17',
                                            color: '#0090B5',
                                            '& fieldset': {
                                                borderColor: 'transparent',
                                            },
                                            '&:hover fieldset': {
                                                borderColor: 'transparent',
                                            },
                                            '&.Mui-focused fieldset': {
                                                borderColor: 'transparent',
                                            },
                                        },
                                        '& .MuiInputLabel-root': {
                                            color: '#00796B',
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                        <Divider />
                        <Typography variant="h6" color={'#035A89'} mt={1} gutterBottom textAlign="center">
                            Datos de hijos
                        </Typography>

                        {
                            students.length === 0 && (
                                <>
                                    <Box display="grid" gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"} gap={1} mb={1} alignItems="center" alignContent={'center'} justifyContent="center">
                                        <Box alignItems="center" justifyContent="center">
                                            <Typography textAlign={'left'} color="#06314E" sx={{ fontSize: '15px' }}>Ingresa el rut de tu hijo</Typography>
                                            <TextField
                                                variant="outlined"
                                                name="rutStudent"
                                                value={rutStudent}
                                                onChange={handleInputChange}
                                                placeholder='Rut alumno'
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiOutlinedInput-root': {
                                                        borderRadius: '12px',
                                                        backgroundColor: '#06314E17',
                                                        color: '#0090B5',
                                                        '& fieldset': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&:hover fieldset': {
                                                            borderColor: 'transparent',
                                                        },
                                                        '&.Mui-focused fieldset': {
                                                            borderColor: 'transparent',
                                                        },
                                                    },
                                                    '& .MuiInputLabel-root': {
                                                        color: '#00796B',
                                                    },
                                                }}
                                            />
                                        </Box>

                                        <Box sx={{
                                            display: 'flex',
                                            flex: 1,
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            alignContent: 'center',
                                            justifyContent: 'center',
                                            justifyItems: 'center',
                                            color: 'white'
                                        }}>

                                            <Box
                                                sx={{
                                                    height: '15px'
                                                }}
                                            />

                                            <Button
                                                variant="contained"
                                                fullWidth
                                                disableElevation
                                                sx={{ mt: 1, bgcolor: '#007190', borderRadius: '15px', textTransform: 'none', height: '50px', color: 'white' }}
                                                onClick={handleVerify}
                                            >
                                                Verificar rut
                                            </Button>
                                        </Box>


                                    </Box>

                                </>
                            )
                        }
                        {
                            students && students.map((student, index) => (
                                <>
                                    {student.isComplete ? (
                                        <Box sx={{
                                            mb: 2,
                                            p: 2,
                                            borderRadius: '10px',
                                            border: '1px solid #C2C7CF'
                                        }}>
                                            <Box display="grid" gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"} gap={2} mb={1}>

                                                <Box sx={{
                                                    width: '95%'
                                                }}>
                                                    <Typography textAlign={'left'} color="#06314E" sx={{ fontSize: '15px' }}>Curso actual de alumno (2024)</Typography>
                                                    <Box sx={{
                                                        p: '10px',
                                                        width: '100%',
                                                        borderRadius: '10px',
                                                        backgroundColor: '#06314E17'
                                                    }}>
                                                        <Typography color="#0090B5">
                                                            {convertirCurso(student.course).replaceAll('-', ' ')}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={{
                                                    width: '95%'
                                                }}>
                                                    <Typography textAlign={'left'} color="#06314E" sx={{ fontSize: '15px' }}>Nombre</Typography>
                                                    <Box sx={{
                                                        p: '10px',
                                                        width: '100%',
                                                        borderRadius: '10px',
                                                        backgroundColor: '#06314E17'
                                                    }}>
                                                        <Typography color="#0090B5">
                                                            {student.name}
                                                        </Typography>
                                                    </Box>

                                                </Box>
                                            </Box>
                                            {/* <center>
                                                <IconButton onClick={() => handleRemoveStudent(index)} color={colors.blue} >
                                                    <Delete sx={{
                                                        color: 'red'
                                                    }} />
                                                </IconButton>
                                            </center> */}

                                        </Box>
                                    ) : (
                                        <>
                                            <Box key={index} display="grid" gridTemplateColumns={isMobile ? "1fr" : "1fr 1fr"} gap={1} mb={1} alignItems="center" justifyContent="center">
                                                <Box alignItems="center" justifyContent="center">
                                                    <Typography textAlign={'left'} color="#06314E" sx={{ fontSize: '15px' }}>Ingresa el rut de tu hijo</Typography>
                                                    <TextField
                                                        variant="outlined"
                                                        name="rutStudent"
                                                        value={rutStudent}
                                                        onChange={handleInputChange}
                                                        placeholder='Rut alumno'
                                                        sx={{
                                                            width: '100%',
                                                            '& .MuiOutlinedInput-root': {
                                                                borderRadius: '12px',
                                                                backgroundColor: '#06314E17',
                                                                color: '#0090B5',
                                                                '& fieldset': {
                                                                    borderColor: 'transparent',
                                                                },
                                                                '&:hover fieldset': {
                                                                    borderColor: 'transparent',
                                                                },
                                                                '&.Mui-focused fieldset': {
                                                                    borderColor: 'transparent',
                                                                },
                                                            },
                                                            '& .MuiInputLabel-root': {
                                                                color: '#00796B',
                                                            },
                                                        }}
                                                    />
                                                </Box>

                                                <center>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        alignContent: 'center',
                                                        justifyContent: 'center',
                                                        justifyItems: 'center',
                                                        color: 'white'
                                                    }}>
                                                        <Button
                                                            variant="contained"

                                                            fullWidth
                                                            disableElevation
                                                            sx={{ mt: 1, bgcolor: '#007190', borderRadius: '15px', textTransform: 'none', height: '50px', color: 'white' }}
                                                            onClick={handleVerify}
                                                        >
                                                            Verificar rut
                                                        </Button>
                                                    </Box>
                                                </center>


                                            </Box>

                                        </>

                                    )}
                                </>
                            ))}

                        {/* <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            disableElevation
                            sx={{ mt: 1, bgcolor: '#B8E7FF', borderRadius: '15px', textTransform: 'none', height: '50px', color: colors.blue }}
                            onClick={() => {
                                console.log(students);

                                if (students) {
                                    handleAddStudent(students.length - 1)
                                }
                            }}
                        >
                            Agregar alumno
                        </Button> */}
                        {
                            students.length > 0 && (
                                <Button sx={{ mt: 1, bgcolor: 'transparent', borderRadius: '15px', textTransform: 'none', height: '50px' }} onClick={() => {
                                    setStudents([])
                                }} variant="outlined" color="secondary" fullWidth>
                                    Ingresar otro rut
                                </Button>
                            )
                        }
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                validateFields();
                            }}
                            disableElevation
                            sx={{ mt: 1, bgcolor: '#007190', borderRadius: '15px', textTransform: 'none', height: '50px' }}

                        >
                            Continuar al pago
                        </Button>

                    </Paper>
                )}

                {screenNumber === 2 && (
                    <Paper elevation={0} style={{ padding: '20px', width: '100%', backgroundColor: '#DFF2FF', textAlign: 'center', borderRadius: '15px' }}>
                        <Typography variant="h5" gutterBottom sx={{ color: '#06314E', fontWeight: 'bold' }}>
                            Detalles de la compra
                        </Typography>

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Typography sx={{ color: '#06314E99' }}>RUT del comprador</Typography>
                            <Typography sx={{ color: '#06314E' }}>{rut}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Typography sx={{ color: '#06314E99' }}>Nombre del comprador</Typography>
                            <Typography sx={{ color: '#06314E' }}>{name}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                            <Typography sx={{ color: '#06314E99' }}>Email del comprador</Typography>
                            <Typography sx={{ color: '#06314E' }}>{email}</Typography>
                        </Box>
                        <Divider />

                        {students.map((student, index) => (
                            <React.Fragment key={index}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 1 }}>
                                    <Typography sx={{ color: '#06314E99' }}>Curso del Estudiante</Typography>
                                    <Typography sx={{ color: '#06314E' }}>{student.course}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography sx={{ color: '#06314E99' }}>Nombre del Estudiante</Typography>
                                    <Typography sx={{ color: '#06314E' }}>{student.name}</Typography>
                                </Box>
                                <Divider />
                            </React.Fragment>
                        ))}

                        {/* Subtotal, IVA, Total */}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', mt: 1, mb: 2 }}>
                            <Typography sx={{ fontSize: '1.5em', color: '#06314E' }}>Total</Typography>
                            <Typography sx={{ fontSize: '1.5em', color: '#06314E' }}>{`$33.000`}</Typography>
                        </Box>

                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => {
                                handlePayment();
                            }}
                            disableElevation
                            sx={{ mt: 1, bgcolor: '#007190', borderRadius: '15px', textTransform: 'none', height: '50px' }}
                        >
                            Proceder al pago
                        </Button>

                        <Button sx={{ mt: 1, bgcolor: 'transparent', borderRadius: '15px', textTransform: 'none', height: '50px' }} onClick={handlePreviousScreen} variant="outlined" color="secondary" fullWidth>
                            Volver
                        </Button>

                    </Paper>
                )}

                {screenNumber === 3 && (
                    <Paper elevation={0} style={{ padding: '20px', width: '100%', backgroundColor: '#DFF2FF', textAlign: 'center', borderRadius: '15px' }}>
                        <CheckCircleOutlineOutlined sx={{ fontSize: '64px', color: 'green', marginBottom: '10px' }} />
                        <Typography variant="h5" sx={{ color: '#035A89', fontWeight: 'bold', fontSize: '24px', fontFamily: 'nunito' }}>
                            Tu pago fue validado
                        </Typography>

                        <Divider sx={{ my: 2 }} />

                        <Typography variant="h6" sx={{ color: '#035A89', fontWeight: 'bold', textAlign: 'left', fontFamily: 'nunito' }}>
                            Detalles de la compra
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, mb: 1 }}>
                            <Typography sx={{ color: '#06314E99', fontFamily: 'nunito' }}>RUT del comprador</Typography>
                            <Typography>{rut}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                            <Typography sx={{ color: '#06314E99', fontFamily: 'nunito' }}>Nombre del comprador</Typography>
                            <Typography>{name}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                            <Typography sx={{ color: '#06314E99' }}>Email del comprador</Typography>
                            <Typography>{email}</Typography>
                        </Box>

                        <Divider sx={{ my: 2 }} />

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                            <Typography variant="h6" sx={{ color: '#035A89', fontWeight: 'bold', fontFamily: 'nunito' }}>Alumnos pagados</Typography>
                            <Typography>{students.length}</Typography>
                        </Box>

                        {students.map((student, index) => (
                            <React.Fragment key={index}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, mb: 1 }}>
                                    <Typography sx={{ color: '#06314E99', fontFamily: 'nunito' }}>Curso del Estudiante</Typography>
                                    <Typography>{student.course}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                                    <Typography sx={{ color: '#06314E99', fontFamily: 'nunito' }}>Nombre del Estudiante</Typography>
                                    <Typography>{student.name}</Typography>
                                </Box>
                                <Divider sx={{ my: 1 }} />
                            </React.Fragment>
                        ))}

                        <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', mt: 3, mb: 2 }}>
                            <Typography sx={{ fontSize: '1.5em', color: '#035A89' }}>Total</Typography>
                            <Typography sx={{ fontSize: '1.5em' }}>{`$${'33.000'.toLocaleString()}`}</Typography>
                        </Box>

                        <Divider sx={{ my: 2 }} />

                        <Typography variant="body2" sx={{ color: '#0D47A1', mb: 2, fontFamily: 'nunito' }}>
                            Te enviaremos un correo desde info@maxipos.cl con una copia de tu comprobante de pago. Asegúrate de revisar tu carpeta de correo no deseado o spam en caso de no verlo en tu bandeja de entrada.
                        </Typography>
                        <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#0D47A1', mb: 2, fontFamily: 'nunito' }}>
                            {email}
                        </Typography>

                        <Button onClick={() => {
                            setStudents([{ nameStudent: '', course: '', isComplete: false }])
                            setScreenNumber(1)
                            setName('')
                            setEmail('')
                            setRut('')
                            setRutStudent('')
                        }} variant="outlined" color="primary" sx={{ textTransform: 'none', borderRadius: '15px' }}>
                            Volver al inicio
                        </Button>
                    </Paper>
                )}
            </Container>
            <ModalVideoApoderados
                videoId="dQw4w9WgXcQ"
                title="Tutorial de pago"
                openByDefault={openModal1}
            />
        </div >




    );
};

export default PayPage;
