import { collection, query, where, onSnapshot, doc, getDoc, orderBy, getDocs, addDoc, serverTimestamp, updateDoc } from 'firebase/firestore';
import { db } from './config_firebase';

export const createNewBingo = async (data) => {
    try {

        const docRef = await addDoc(collection(db, "bingos"), data);

        console.log("Documento añadido con ID: ", docRef.id);
        return docRef.id;

    } catch (e) {
        console.error("Error al agregar el documento: ", e);
        // throw e;
        return false;
    }
};

export const getBingoStatus = async (idCard) => {
    const BingosCollection = collection(db, 'bingos');
    const bingosQuery = query(BingosCollection, where('idCard', '==', idCard));
    const querySnapshot = await getDocs(bingosQuery);

    const bingos = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));

    console.log("Buscando", idCard, bingos);


    if (bingos.length > 0) {
        return 'ya existe';
    } else {
        return 'no existe';
    }
}

export const getBingoData = async (idCard) => {
    const BingosCollection = collection(db, 'bingos');
    const bingosQuery = query(BingosCollection, where('idCard', '==', idCard));
    const querySnapshot = await getDocs(bingosQuery);

    const bingos = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
    }));

    console.log("Buscando", idCard, bingos);


    if (bingos.length > 0) {
        return bingos[0]
    } else {
        return false
    }
}


export const getPaysDataBingos = async () => {
    let estadisticas = {
        pagosPorCurso: {},
        generales: [],
        entradasGenerales: 0,
        montoRecaudadoPorCurso: {},
        entradasCortesia: 0,
        montoTotalGeneral: 0,
        transaccionesPorTipo: { alumno: 0, general: 0 }
    };

    const ordenCursos = [
        "Pre-Kinder", "Kinder", "Primero", "Segundo", "Tercero", "Cuarto",
        "Quinto", "Sexto", "Séptimo", "Octavo",
        "Primero-Medio", "Segundo-Medio", "Tercero-Medio", "Cuarto-Medio"
    ];

    try {
        const pagosRef = collection(db, 'bingos');
        const q = query(pagosRef, where("status", "==", "payed"));
        const snapshot = await getDocs(q);

        snapshot.forEach(doc => {
            const pago = doc.data();
            const amount = parseFloat(pago.amount) || 0;

            if (pago.ticketType === "general" && pago.signature !== 'no aplica') {
                estadisticas.entradasGenerales++;
                estadisticas.generales.push(pago);
                estadisticas.transaccionesPorTipo.general++;
            } else if (pago.ticketType === "alumno" && Array.isArray(pago.students)) {

                const student = pago.studentToPay;
                const curso = student.course;
                const nombreEstudiante = student.name || "Sin Nombre";
                const idEstudiante = student.idCard || "Sin ID";

                if (!estadisticas.pagosPorCurso[curso]) {
                    estadisticas.pagosPorCurso[curso] = [];
                    estadisticas.montoRecaudadoPorCurso[curso] = 0;
                }

                let estudiante = estadisticas.pagosPorCurso[curso].find(e => e.nombre === nombreEstudiante);

                if (estudiante) {
                    estudiante.idCards.push(idEstudiante);
                    estudiante.cantidad += 1;
                } else {
                    estadisticas.pagosPorCurso[curso].push({
                        nombre: nombreEstudiante,
                        idCards: [idEstudiante],
                        estado: "Pagado",
                        cantidad: 1,
                    });
                }

                estadisticas.montoRecaudadoPorCurso[curso] += 10000;
                estadisticas.transaccionesPorTipo.alumno++;
            } else if (pago.result === "no aplica") {
                estadisticas.entradasCortesia++;
            }

            estadisticas.montoTotalGeneral += amount;
        });

        const cursosOrdenados = Object.keys(estadisticas.pagosPorCurso).sort((a, b) => {
            const [nivelA, seccionA] = a.split('-', 2);
            const [nivelB, seccionB] = b.split('-', 2);

            const indexA = ordenCursos.indexOf(nivelA);
            const indexB = ordenCursos.indexOf(nivelB);

            if (indexA !== indexB) {
                return indexA - indexB;
            }
            return (seccionA || '').localeCompare(seccionB || '');
        });

        const pagosPorCursoOrdenado = {};
        cursosOrdenados.forEach(curso => {
            pagosPorCursoOrdenado[curso] = estadisticas.pagosPorCurso[curso].sort((a, b) =>
                a.nombre.localeCompare(b.nombre)
            );
        });

        estadisticas.pagosPorCurso = pagosPorCursoOrdenado;

        return estadisticas;
    } catch (error) {
        console.error("Error obteniendo estadísticas de pagos: ", error);
        return null;
    }
};

export const checkBoletoExists = async (idCard) => {

    const q = query(collection(db, "bingos"), where("idCard", "==", idCard));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
};