import React, { useEffect, useState } from 'react';
import ReusableDrawer from '../components/drawer';
import { getStudents } from '../database/students';
import { Box, ListItem, ListItemAvatar, ListItemText, Avatar, Typography, Pagination, TextField, InputAdornment, MenuItem, FormControl, InputLabel, Select, ListItemIcon, Button } from '@mui/material';
import { ArrowForwardIos, Check, ErrorOutline, ForwardToInbox, Search } from '@mui/icons-material';
import colors from '../utils/colors';
import loadingImg from '../assets/loading.svg';
import { capitalize, capitalizeWords } from '../utils/capitalize';
import { getAllCourses } from '../database/courses';
import Swal from 'sweetalert2';
import axios from 'axios';
import { generateExcelStudents } from '../utils/generate_excel_families';
import { getFamilies, getNamesPayed } from '../database/family';


export default function Students() {
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [itemsPerPage] = useState(8);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [payedStudents, setPayedStudents] = useState([])
    const [selectedType, setSelectedType] = useState("all");
    const [courseOptions, setCourseOptions] = useState([])
    const [selectedCourse, setSelectedCourse] = useState('all');

    const checkStudentExists = (studentName) => {
        return payedStudents.includes(capitalizeWords(studentName));
    };

    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedStudents = filteredStudents.slice(startIndex, endIndex);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleCourseChange = (event) => {
        setSelectedCourse(event.target.value);
    };

    useEffect(() => {
        const fetchStudents = async () => {
            setLoading(true);
            const response = await getStudents();
          
            const response2 = await getNamesPayed(selectedType, selectedCourse);

            setPayedStudents(response2.studentNames);
            const updatedStudents = response.students.map(student => ({
                ...student,
                isPaid: response2.studentNames.includes(capitalizeWords(student.nombre))
            }));
    
            setStudents(updatedStudents);
            
            let itemcourses = response.courses.map((el) => ({
                value: el,
                label: el
            }));
            setCourseOptions(itemcourses)
            setLoading(false);
        };
        fetchStudents();
    }, []);

    const sendMailReminder = async (rut, name, email) => {
        const result = await Swal.fire({
            title: 'Enviar Recordatorio de pago',
            text: '¿Estás seguro de que deseas enviar un recordatorio de pago al responsable económico de la familia?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, enviar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            const response = await axios.post('https://us-central1-apoderados-fd064.cloudfunctions.net/sendPaymentReminderEmail', {
                name: name,
                paymentLink: `https://salle.maxipos.cl/pay?rut=${rut}`,
                email: email,
                subject: "Recordatorio de pago"
            })
            if (response.status === 200) {
                Swal.fire(
                    'Enviado!',
                    'El recordatorio de pago ha sido enviado.',
                    'success'
                );
            } else {
                Swal.fire(
                    'Error',
                    'El recordatorio no ha podido enviarse. Revisa el correo del apoderado.',
                    'error'
                );
            }

        }
    };

    useEffect(() => {
        const lowercasedFilter = searchTerm.toLowerCase();
        const filteredData = students.filter(student => {
            const matchesSearch = (student.nombre && student.nombre.toLowerCase().includes(lowercasedFilter)) ||
                (student.nombre && student.nombre.toLowerCase().includes(lowercasedFilter));
            const matchesCourse = selectedCourse === 'all' || student.curso === selectedCourse;
            return matchesSearch && matchesCourse;
        });
        setFilteredStudents(filteredData);
        setPage(1);
    }, [searchTerm, selectedCourse, students]);


    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'start',
            backgroundColor: '#F2FAFF',
            flex: 1,
            height: '100vh',
            overflowX: 'hidden'
        }}>
            <ReusableDrawer isOpen={drawerOpen} onClose={toggleDrawer} route={'students'} />
            {loading ? (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '90%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                }}>
                    <img src={loadingImg} alt="loading" style={{ width: '100px', height: '100px' }} />
                    <p>Cargando estudiantes...</p>
                </Box>
            ) : (
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '90%',
                    alignItems: 'center',
                }}>
                    <ListItem sx={{ paddingX: 5 }}>
                        <ListItemText sx={{ color: colors.green }} primary={`Todos los estudiantes (${students.length})`}
                            primaryTypographyProps={{
                                fontFamily: 'Nunito',
                                fontSize: '25px',
                                fontWeight: 'bold',
                                color: colors.blue,
                                textAlign: 'center'
                            }} />
                    </ListItem>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '90%', mb: 2 }}>
                        <Box display={'flex'} width={'60%'}>
                            <TextField
                                placeholder="Buscar"
                                variant="outlined"
                                sx={{
                                    mr: 1,
                                    width: '60%',
                                    backgroundColor: '#00000017',
                                    borderRadius: '50px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                    },
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    )
                                }}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />

                            <Select
                                value={selectedCourse}
                                onChange={handleCourseChange}
                                variant="outlined"
                                label="Selecciona un curso"
                                sx={{
                                    width: '30%',
                                    color: 'white',
                                    mr: 1,
                                    height: '50px',
                                    backgroundColor: colors.blue,
                                    fontFamily: 'Nunito',
                                    borderRadius: '50px',
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            border: 'none',
                                        },
                                    },
                                    '& .MuiSelect-icon': {
                                        color: 'white',
                                    },
                                }}
                            >
                                <MenuItem value="all">
                                    Todos los cursos
                                </MenuItem>
                                {courseOptions.map((course) => (
                                    <MenuItem key={course.value} value={course.value}>
                                        {course.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>

                        <Button
                            onClick={() => {
                                generateExcelStudents(filteredStudents)
                            }}
                            sx={{
                                fontFamily: 'Nunito',
                                width: '15%',
                                backgroundColor: '#07A7F0',
                                color: 'white',
                                textTransform: 'none',
                                borderRadius: '20px',
                                ':hover': {
                                    backgroundColor: colors.blue
                                },
                                fontSize: '14px',
                                p: 0,
                                m: 0
                            }}>
                            Exportar a excel
                        </Button>

                    </Box>
                    <Box sx={{
                        mb: 2,
                        width: '90%',
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                    </Box>

                    {paginatedStudents.map((student) => (
                        <ListItem
                            key={student.id}
                            onClick={() => console.log(student)}
                            sx={{
                                cursor: 'pointer',
                                margin: '2px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '90%',
                                backgroundColor: 'white',
                                borderRadius: '10px',
                                border: '1px solid #1212121F',
                            }}
                        >
                            <ListItemAvatar>
                                <Avatar sx={{ backgroundColor: colors.blue }}>
                                    {student.nombre ? capitalize(student.nombre.substring(0, 1)) : '?'}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText sx={{ ml: 1 }}
                                primaryTypographyProps={{
                                    fontFamily: 'Nunito'
                                }}
                                secondaryTypographyProps={{
                                    fontFamily: 'Nunito'
                                }}
                                primary={`${capitalizeWords(student.nombre)}`}
                                secondary={`${capitalizeWords(student.rut)}`}

                            />

                            <ListItemIcon>
                                {
                                    student.isPaid ? (
                                        <Check sx={{
                                            color: 'green',
                                            fontSize: '30px'
                                        }} />
                                    ) : (
                                        <ErrorOutline sx={{
                                            color: 'red',
                                            fontSize: '30px'
                                        }} />
                                    )
                                }
                            </ListItemIcon>



                        </ListItem>
                    ))}
                    <Pagination
                        count={Math.ceil(filteredStudents.length / itemsPerPage)}
                        page={page}
                        onChange={handleChangePage}
                        sx={{ mt: 2 }}
                    />
                </Box>
            )}
        </div>
    );
}
