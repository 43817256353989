import { addDoc, collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from './config_firebase';
import * as XLSX from 'xlsx';

export const getBingoStudents = async () => {
    try {
        const studentsCollection = collection(db, 'bingo_students');

        const studentsQuery = query(studentsCollection, orderBy('boleto'));

        const querySnapshot = await getDocs(studentsQuery);

        const students = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        return students;
    } catch (error) {
        console.error('Error fetching students:', error);
        return [];
    }
};

export const verifyStudent = async (rut) => {
    try {
        const studentsRef = collection(db, 'alumnos_matriculas');
        const rutQuery = query(studentsRef, where('rut', '==', rut));
        const rutSnapshot = await getDocs(rutQuery);

        if (rutSnapshot.empty) {
            console.log('Estudiante no encontrado');
            return [];
        }

        const studentDoc = rutSnapshot.docs[0];
        const studentData = studentDoc.data();
        const codigoFamilia = studentData.codigoFamilia;

        const familyQuery = query(studentsRef, where('codigoFamilia', '==', codigoFamilia));
        const familySnapshot = await getDocs(familyQuery);

        const familyMembers = familySnapshot.docs.map(doc => ({
            name: doc.data().nombre,
            course: doc.data().curso,
            rut: doc.data().rut,
            isComplete: true
        }));

        return familyMembers;

    } catch (error) {
        console.error('Error buscando estudiantes:', error);
        return [];
    }
};

const sortCourses = (courses) => {
    // Mapeo de niveles para el sistema educativo de Chile
    const levelOrder = {
        "Pre-Kinder": 1,
        "Kinder": 2,
        "Primero": 3,
        "Segundo": 4,
        "Tercero": 5,
        "Cuarto": 6,
        "Quinto": 7,
        "Sexto": 8,
        "Séptimo": 9,
        "Octavo": 10,
        "Primero-Medio": 11,
        "Segundo-Medio": 12,
        "Tercero-Medio": 13,
        "Cuarto-Medio": 14,
    };

    return courses.sort((a, b) => {
        // Extraer el nivel y subnivel del curso
        const [nameA, sublevelA] = a.split(/-(.+)/); // Divide en el primer guion
        const [nameB, sublevelB] = b.split(/-(.+)/);

        // Obtener el orden basado en el mapeo
        const levelA = levelOrder[nameA.trim()] || 999; // Por defecto 999 para no definidos
        const levelB = levelOrder[nameB.trim()] || 999;

        // Ordenar primero por nivel educativo
        if (levelA !== levelB) {
            return levelA - levelB;
        }

        // Si están en el mismo nivel, ordenar por subnivel (A, B, etc.)
        return (sublevelA || "").localeCompare(sublevelB || "");
    });
};

export const getStudents = async () => {
    try {
        const studentsCollection = collection(db, 'alumnos_matriculas');
        const querySnapshot = await getDocs(studentsCollection);

        const students = [];
        const coursesSet = new Set();

        querySnapshot.docs.forEach((doc) => {
            const data = doc.data();
            const { nombre, curso, rut } = data;

            const [lastName, firstName] = nombre.split(',').map((part) => part.trim());
            const formattedName = `${firstName} ${lastName}`; 

            students.push({
                id: doc.id,
                nombre: formattedName,
                curso: convertirCurso(curso),
                rut,
            });

            coursesSet.add(convertirCurso(curso));
        });

        const courses = Array.from(coursesSet);

        return { students, courses: sortCourses(courses) };
    } catch (error) {
        console.error('Error obteniendo los estudiantes:', error);
        return { students: [], courses: [] };
    }
};

// export const getStudents = async () => {
//     try {
//         const studentsCollection = collection(db, 'students');
//         const studentsQuery = query(studentsCollection, orderBy('name'));
//         const querySnapshot = await getDocs(studentsQuery);

//         const students = querySnapshot.docs.map(doc => ({
//             id: doc.id,
//             ...doc.data(),
//         }));

//         const familyIds = students
//             .filter(student => student.familyId)
//             .map(student => student.familyId);

//         let families = [];
//         if (familyIds.length > 0) {
//             const familiesCollection = collection(db, 'families');
//             const familiesQuery = query(familiesCollection, where('__name__', 'in', familyIds));
//             const familiesSnapshot = await getDocs(familiesQuery);

//             families = familiesSnapshot.docs.map(doc => ({
//                 id: doc.id,
//                 ...doc.data(),
//             }));
//         }

//         const studentsWithFamilies = students.map(student => {
//             const family = families.find(family => family.id === student.familyId);
//             return {
//                 ...student,
//                 family: family || null,
//             };
//         });

//         return studentsWithFamilies;
//     } catch (error) {
//         console.error('Error fetching students:', error);
//         return [];
//     }
// };

export const convertirCurso = (curso) => {
    const regexBasico = /^(\d)°([A-C])$/;
    const regexMedio = /^(I{1,3}|IV)°([A-C])$/;
    const regexKinder = /^(K|PK)-([A-C])$/;

    if (regexBasico.test(curso)) {
        const [, numero, seccion] = curso.match(regexBasico);
        const grados = ["Primero", "Segundo", "Tercero", "Cuarto", "Quinto", "Sexto", "Séptimo", "Octavo"];
        return `${grados[parseInt(numero) - 1]}-${seccion}`;
    } else if (regexMedio.test(curso)) {
        const [, nivel, seccion] = curso.match(regexMedio);
        const gradosMedio = {
            "I": "Primero-Medio",
            "II": "Segundo-Medio",
            "III": "Tercero-Medio",
            "IV": "Cuarto-Medio"
        };
        return `${gradosMedio[nivel]}-${seccion}`;
    } else if (regexKinder.test(curso)) {
        const [, tipo, seccion] = curso.match(regexKinder);
        const gradosKinder = {
            "K": "Kinder",
            "PK": "Pre-Kinder"
        };
        return `${gradosKinder[tipo]}-${seccion}`;
    }

    return curso;
};



export async function uploadStudents(file) {
    try {
        const data = await file.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        const rows = XLSX.utils.sheet_to_json(sheet);

        rows.forEach(async (row) => {
            const [apellido, nombre] = row["Nombre"].split(", ");
            const curso = convertirCurso(row["Curso"]);
            const boleto = row["Boleto"];

            try {
                await addDoc(collection(db, "bingo_students"), {
                    nombre: nombre.trim(),
                    apellido: apellido.trim(),
                    curso: curso,
                    boleto: boleto
                });
                console.log(`Alumno ${nombre} ${apellido} añadido con éxito.`);
            } catch (e) {
                console.error("Error al añadir alumno: ", e);
            }
        });
        return true;
    } catch (error) {
        console.log("Error");
        return false;
    }
}