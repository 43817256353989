import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer, IconButton,  Container, Avatar, List, ListItem, ListItemAvatar, ListItemText, ListItemIcon } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'
import { BarChartOutlined, Book, Download, ExitToApp, FamilyRestroom, Home, HomeOutlined, MailOutline, MenuBook, Payments, Person2Outlined, Person3Outlined, PersonOutline, PieChartOutline, SettingsOutlined } from '@mui/icons-material';
import logo from '../assets/logo_mini.svg';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../utils/colors';
import maxipos from '../assets/star.png';
import { useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../database/config_firebase';
import { logout } from "../actions/auth_actions";
import { capitalize } from '../utils/capitalize';
import BingoPdfModal from './bingo_pdf_generator';

const ReusableDrawer = ({ onClose, children, anchor = 'left', width = 280, route, isOpen }) => {

  const userData = useSelector(state => state.auth.userData);

  const [openDrawer, setOpenDrawer] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(logout());
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };



  return (
    <Drawer
      anchor={anchor}
      open={isOpen}
      onClose={onClose}
      
      variant="permanent"
      sx={{
        zIndex: 0,
        width: width,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: width,
          backgroundColor: 'white',
          boxSizing: 'border-box',
          // borderTopRightRadius: 10,
          // borderBottomRightRadius: 10,
        },
      }}
    >
      <Container sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        mt: 2
      }}>
        <img src={logo} width={'100%'} alt="Logo" />
      </Container>
      <ListItem sx={{
        mt: 2,
        ml: 2,
        border: '1px solid #1212121F',
        backgroundColor: '#F2FAFF',
        display: 'flex',
        justifyContent: 'space-evenly',
        flexDirection: 'row',
        width: '90%',
        borderRadius: '10px'
      }}>
        <ListItemAvatar>
          <Avatar sx={{
            border: '1px solid #1212121F',
          }} src={
            maxipos
          } />
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{ fontWeight: 'bold' }}
          primary={userData && userData.name ? userData.name : 'Admin'}
          secondary={userData && userData.type ? capitalize(userData.type) : ''}
        />
      </ListItem>
      <List sx={{ mt: 2, flex: 1, justifyContent: 'flex-start' }}>
        <Container>
          <ListItem
            onClick={() => {
              navigate('/home');
            }}
            sx={{
              '&:hover': {
                backgroundColor: route == 'home' ? colors.blueLight : colors.blueTransparent
              },
              borderRadius: '20px',
              color: route === 'home' ? colors.white : colors.blueLight,
              backgroundColor: route === 'home' ? colors.blue : 'transparent',
              border: route === 'home' ? `1px solid ${route === 'home' ? colors.white : 'transparent'}` : 'none',
              p: 2,
              mt: 1
            }} button>
            <ListItemIcon>
              <HomeOutlined sx={{
                color: route === 'home' ? colors.white : colors.blueLight,
              }} />
            </ListItemIcon>
            Inicio
          </ListItem>

          <ListItem
            onClick={() => {
              navigate('/families');
            }}
            sx={{
              '&:hover': {
                backgroundColor: route == 'families' ? colors.blueLight : colors.blueTransparent
              },
              borderRadius: '20px',
              color: route === 'families' ? colors.white : colors.blueLight,
              backgroundColor: route === 'families' ? colors.blue : 'transparent',
              border: route === 'families' ? `1px solid ${route === 'families' ? colors.white : 'transparent'}` : 'none',
              p: 2,
              mt: 1
            }} button>
            <ListItemIcon>
              <FamilyRestroom sx={{
                color: route === 'families' ? colors.white : colors.blueLight,
              }} />
            </ListItemIcon>
            Familias
          </ListItem>

          <ListItem
            onClick={() => {
              navigate('/students');
            }}
            sx={{
              '&:hover': {
                backgroundColor: route == 'students' ? colors.blueLight : colors.blueTransparent
              },
              borderRadius: '20px',
              color: route === 'students' ? colors.white : colors.blueLight,
              backgroundColor: route === 'students' ? colors.blue : 'transparent',
              border: route === 'students' ? `1px solid ${route === 'students' ? colors.white : 'transparent'}` : 'none',
              p: 2,
              mt: 1
            }} button>
            <ListItemIcon>
              <Book sx={{
                color: route === 'students' ? colors.white : colors.blueLight,
              }} />
            </ListItemIcon>
            Estudiantes
          </ListItem>

          {/* <ListItem
            onClick={() => {
              navigate('/pendingPays');
            }}
            sx={{
              '&:hover': {
                backgroundColor: route == 'pendingPays' ? colors.blueLight : colors.blueTransparent
              },
              borderRadius: '10px',
              color: route === 'pendingPays' ? colors.white : colors.blueLight,
              backgroundColor: route === 'pendingPays' ? colors.blue : 'transparent',
              border: route === 'pendingPays' ? `1px solid ${route === 'pendingPays' ? colors.white : 'transparent'}` : 'none',
              p: 2,
              mt: 1
            }} button>
            <ListItemIcon>
              <Payments sx={{
                color: route === 'pendingPays' ? colors.white : colors.blueLight,
              }} />
            </ListItemIcon>
            Pendientes de pago
          </ListItem> */}
          {/* <ListItem
            onClick={() => {
              navigate('/settings');
            }}
            sx={{
              '&:hover': {
                backgroundColor: route == 'settings' ? colors.blueLight : colors.blueTransparent
              },
              borderRadius: '20px',
              p: 2,
              mt: 1,
              color: route === 'settings' ? colors.white : colors.blueLight,
              backgroundColor: route === 'settings' ? colors.blue : 'transparent',
              border: route === 'settings' ? `1px solid ${route === 'pendingPays' ? colors.white : 'transparent'}` : 'none',
            }} button>
            <ListItemIcon>
              <SettingsOutlined sx={{
                color: route === 'settings' ? colors.white : colors.blueLight,
              }} />
            </ListItemIcon>
            Ajustes
          </ListItem> */}
        </Container>
        <Container style={{
          position: 'absolute',
          bottom: 10,
          width: '100%'
        }}>
          {/* <ListItem
            onClick={() => {
              handleOpenModal();
            }}
            sx={{
              '&:hover': {
                backgroundColor: route == 'bingoReports' ? colors.blueLight : colors.blueTransparent
              },
              borderRadius: '10px',
              p: 2,
              mt: 1,
              color: route === 'bingoReports' ? colors.white : colors.blueLight,
              backgroundColor: route === 'bingoReports' ? colors.blue : 'transparent',
              border: route === 'bingoReports' ? `1px solid ${route === 'pendingPays' ? colors.white : 'transparent'}` : 'none',
            }} button>
            <ListItemIcon>
              <Download sx={{
                color: route === 'bingoReports' ? colors.white : colors.blueLight,
              }} />
            </ListItemIcon>
            Generar PDF Bingo
          </ListItem> */}
          <ListItem
            onClick={() => {
              navigate('/bingoReports');
            }}
            sx={{
              '&:hover': {
                backgroundColor: route == 'bingoReports' ? colors.blueLight : colors.blueTransparent
              },
              borderRadius: '20px',
              p: 2,
              mt: 1,
              color: route === 'bingoReports' ? colors.white : colors.blueLight,
              backgroundColor: route === 'bingoReports' ? colors.blue : 'transparent',
              border: route === 'bingoReports' ? `1px solid ${route === 'pendingPays' ? colors.white : 'transparent'}` : 'none',
            }} button>
            <ListItemIcon>
              <BarChartOutlined sx={{
                color: route === 'bingoReports' ? colors.white : colors.blueLight,
              }} />
            </ListItemIcon>
            Reporte de bingos
          </ListItem>
          <ListItem button
            onClick={handleLogout}
            sx={{
              '&:hover': {
                backgroundColor: colors.blueTransparent
              },
              borderRadius: '20px',
              p: 2,
              color: colors.blueLight

            }}>
            <ListItemIcon>
              <ExitToApp sx={{
                color: colors.blueLight
              }} />
            </ListItemIcon>
            Cerrar sesión
          </ListItem>
        </Container>
      </List>
      <BingoPdfModal open={isModalOpen} onClose={handleCloseModal} />
    </Drawer>
  );
};

ReusableDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  anchor: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
  width: PropTypes.number,
};

export default ReusableDrawer;
